import React from 'react'
import usePagination from './UsePagination';
import ProductTemplate from './ProductTemplate';
import { Badge } from 'react-bootstrap';

function PaginateItems({products, cart, updateCart,itemsPerPage}) {
    //const itemsPerPage = 12;
    const items = products;
    const { currentPage, goToPage, paginate } = usePagination(itemsPerPage);
    const paginatedItems = paginate(items);
    

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Optional: Use smooth scrolling
        });
      };

  return (
    <div className='' >
        <div className='w-full h-1/2 grid md:grid-cols-4 gap-4  place-items-center sm:grid-cols-2'>
            {paginatedItems.map((product) => (
                <ProductTemplate data={product} cart = {cart} addTocart={updateCart}/>
            ))}
        </div>
        <div className='flex w-full justify-center items-center border-t-2 py-2'>
            {currentPage!==1&&<button className=' mr-4 p-1 text-blue-600 rounded' onClick={() => {
                scrollToTop();
                goToPage(currentPage - 1)}} disabled={currentPage === 1}>
                {'<<'}Previous Page
            </button>}
            <Badge>Page {currentPage}</Badge>
            <button className='ml-4 p-1 text-blue-600 rounded' style={currentPage===Math.ceil(items.length / itemsPerPage)?{display:'none'}:{}} onClick={() => {
                scrollToTop();
                goToPage(currentPage + 1)}} >
                Next Page{'>>'}
            </button>
        </div>
    </div>
  )
}

export default PaginateItems