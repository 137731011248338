import React from 'react';
import { CDBBtn, CDBIcon } from 'cdbreact';
import Logo from '../images/favicon.png'

export const Footer = () => {
  return (
    <div className="shadow bg-gradient-to-t bg-slate-800">
      <div className="mx-auto pt-2 flex w-10/12 flex-wrap items-center justify-center">
        <div className="md:flex md:items-center md:justify-center">
            <div>
          <a href="/" className="d-flex align-items-center p-0 text-light no-underline">
            <img
              alt="logo"
              src={Logo}
              width="30px"
            />
            <span className="ms-4 h5 mb-0 ">Frantech</span>
          </a>
          </div>
          <small className="ms-2 text-light">&copy; Frantech Electronics, {new Date().getFullYear()}. All rights reserved.</small>
        </div>
        <div className="flex ml-4">
          <CDBBtn flat color="light" className="p-2 no-underline" target='_blank' href='https://www.linkedin.com/company/frantech-electronics/'>
            <CDBIcon fab icon="linkedin" size='lg'/>
          </CDBBtn>
          {/**<CDBBtn flat color="light" className="mx-3 p-2">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="light" className="p-2">
            <CDBIcon fab icon="instagram" />
          </CDBBtn>*/}
        </div>
      </div>
      
      <div className='w-full justify-center items-center flex'>
        <hr className='text-white opacity-100 w-full border-t-2'/>
        <a href="http://www.linkedin.com/in/tafadzwa-ndingindi-15336617b" target="_blank" rel="noopener noreferrer" className=' no-underline text-white px-1 border rounded flex items-center justify-center'>
          <small>Developer</small>
        </a>
        <hr className='text-white w-full opacity-100 border-t-2'/>
      </div>
    </div>
  );
};