import React, { useEffect, useState } from 'react'
import Bg from '../images/electronic-devices.jpg'
import { List,ListItem,ListItemPrefix} from '@material-tailwind/react'
import Repairs from '../images/wallpaperflare.com_wallpaper.jpg'
import PCB from '../images/pcb1.jpg'
import {  Button, CloseButton, Form } from 'react-bootstrap';
import { useRef } from 'react';
import emailjs from '@emailjs/browser'
import loadingSVG from '../images/preloader.svg'
import { Helmet } from 'react-helmet-async'

function Services({setCurrentPage,setLanding}) {
  const [repairsTabOpen,setRepairsTabOpen] = useState(false);
  const [rndTabOpen,setRnDTabOpen] = useState(false);
  const [CPUChecked,setCPUChecked] = useState(false);
  const [TVChecked,setTVChecked] = useState(false);
  const [MPUChecked,setMPUChecked] = useState(false);
  const [PSUChecked,setPSUChecked] = useState(false);
  const [ADBChecked,setADBChecked] = useState(false);
  const [IBChecked,setIBChecked] = useState(false);
  const [clientName,setClientName] = useState('');
  const [clientPhone,setClientPhone] = useState('');
  const [clientEmail,setClientEmail] = useState('');
  const [moreInfo,setMoreInfo] = useState('')
  const [messageSent,setMessageSent] = useState(false)
  const [sending,setSending] = useState(false)

  useEffect(() => {
    setLanding(false);
    setCurrentPage('services')
  },[setLanding,setCurrentPage])

  

  const repairForm = useRef();
  const rndForm = useRef();
   function sendRepairReq(){
    const selectedDevices = [];
    if (CPUChecked) selectedDevices.push('CPU');
    if (TVChecked) selectedDevices.push('TV/Monitor');
    if (MPUChecked) selectedDevices.push('MPU');
    if (ADBChecked) selectedDevices.push('AD Board');
    if (IBChecked) selectedDevices.push('Inverter Board');
    if (PSUChecked) selectedDevices.push('PSU');
    
    
    const htmlString = `
      <h2>New Repair Request</h2>
      <h3>Client Name: ${clientName}</h3>
      <h4>Client Email: ${clientEmail}</h4>
      <h4>Client Phone: ${clientPhone}</h4>
      <p>Selected Devices: ${selectedDevices.join(', ')}.</p>
      <p>Extra Info/Different Device: <strong>${moreInfo}</strong>
    `
    const templateParams = {
      the_html: htmlString,
    }
    console.log('Check Box',htmlString)
    setSending(true)
    emailjs.send('service_vmn997o', 'template_pj42i4a', templateParams, 'POCawG94LKdoqfk7M')
      .then(() => {
        setMessageSent(true)
        setSending(false)
      })
   }

   function sendRnDReq(){
    const htmlString = `
    <style>
      div.moreInfo {
        border-style: solid;
        padding: 2px;
      }
    </style>
    <h2>New R&D Request</h2>
    <h3>Client Name: ${clientName}</h3>
    <h4>Client Email: ${clientEmail}</h4>
    <h4>Client Phone: ${clientPhone}</h4>
    <div class = 'moreInfo'>
    <h5>Project Info:</h5> 
    <p>${moreInfo}</p>
    </div>
    `
    const templateParams = {
      the_html: htmlString,
    }
    setSending(true)
    emailjs.send('service_vmn997o', 'template_pj42i4a', templateParams, 'POCawG94LKdoqfk7M')
      .then(() => {
        setMessageSent(true)
        setSending(false)
      })
   }
  

  return (
    <div className=' min-h-screen bg-cover bg-fixed items-center relative' style={{backgroundImage:`url(${Bg})`}}>
      <Helmet>
        <title>Frantech - Services</title>
      </Helmet>
      <div className="w-full flex items-center justify-center pt-20">
        <h3 className=' text-white'>Our Services</h3> 
      </div>
      {repairsTabOpen&&<div className=' flex justify-center w-screen '>
          <div className=' absolute top-32 2xl:top-40 md:w-7/12 bg-black/30 text-white backdrop-blur shadow'>
          <h4 className='w-full text-center pt-2 border-b shadow-sm pb-1'>Repairs</h4>
            {!messageSent&&!sending&&<div className="pt-1 flex ">
            
              <Form ref={repairForm} className='flex w-full'>
                
                <div className='w-1/2 md:pl-14 pl-2'>
                  <h6>Select Equipment:</h6>
                <List>
                  <ListItem className="p-0  flex hover:cursor-pointer">
                    <label htmlFor='tv' className=' hover:cursor-pointer'>
                    <ListItemPrefix className=" p-1 items-center flex">
                      <Form.Check
                        id='tv'
                        label='TV/Monitors'
                        name='TV/Monitors'
                        
                        onChange={(e)=>setTVChecked(e.target.checked)}
                       
                      />
                    </ListItemPrefix>
                    </label>
                  </ListItem>
                  <ListItem className="p-0">
                    
                  <ListItemPrefix className="p-1">
                    <Form.Check
                    id=''
                    label='Power Supply Units (PSU)'
                    name='PSU'
                    onChange={(e)=>setPSUChecked(e.target.checked)}
                    />
                  </ListItemPrefix>
                      
                    
                  </ListItem>
                  <ListItem className="p-0">
                    <label
                      htmlFor="AD-Boards"
                      className="flex w-full cursor-pointer items-center"
                    >
                      <ListItemPrefix className="p-1">
                        <Form.Check
                          id="AD-Boards"
                          label='AD-Boards'
                          
                          onChange={(e)=>setADBChecked(e.target.checked)}
                        />
                      </ListItemPrefix>
              
                    </label>
                  </ListItem>
                  <ListItem className="p-0">
                    <label
                      htmlFor="Inverter Boards"
                      className="flex w-full cursor-pointer items-center"
                    >
                      <ListItemPrefix className="p-1">
                        <Form.Check
                          id="Inverter Boards"
                          label='Inverter Boards'
                          
                          onChange={(e)=>setIBChecked(e.target.checked)}
                        />
                      </ListItemPrefix>
              
                    </label>
                  </ListItem>
                  <ListItem className="p-0">
                    <label
                      htmlFor="MPU Boards"
                      className="flex w-full cursor-pointer items-center"
                    >
                      <ListItemPrefix className="p-1">
                        <Form.Check
                          id="MPU Boards"
                          label='MPU Boards'
                          
                          onChange={(e)=>setMPUChecked(e.target.checked)}
                        />
                      </ListItemPrefix>
              
                    </label>
                  </ListItem>
                  <ListItem className="p-0">
                    <label
                      htmlFor="CPU Boards"
                      className="flex w-full cursor-pointer items-center"
                    >
                      <ListItemPrefix className="p-1">
                        <Form.Check
                          id="CPU Boards"
                          label='CPU Boards'
                          
                          onChange={(e)=>setCPUChecked(e.target.checked)}
                        />
                      </ListItemPrefix>
              
                    </label>
                  </ListItem>
                  
                </List>
                </div>
                <div className="w-1/2 md:pr-14 pr-2">
                  <h6>Equipment not on the list? <br />Tell us about it:</h6>
                  <Form.Control
                  type='textarea'
                  as={'textarea'}
                  value={moreInfo}
                  onChange={(e) => setMoreInfo(e.target.value)}
                  />
                  <h6>Your Contact Details:</h6>
                  <Form.Control
                    className='mb-3'
                    name='name'
                    placeholder='Name/Company Name'
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                  <Form.Control
                  className='mb-3'
                    name='email'
                    placeholder='Email'
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)}
                  />
                  <Form.Control
                    className='mb-3'
                    name='phone'
                    placeholder='Phone'
                    value={clientPhone}
                    onChange={(e) => setClientPhone(e.target.value)}
                  />
                  <div className=' flex justify-end'>
                  <Button variant='outline-light' onClick={sendRepairReq} disabled={clientEmail!==''?false:true} className='justify-end mb-4' size='sm'>Get In Touch <span><i className="fi fi-rs-paper-plane"></i></span></Button>
                  
                  </div>
                </div>
              </Form>
            </div>}
            {!messageSent&&sending&&<div className="w-full-h-full flex justify-center items-center">
              <img src={loadingSVG} alt="" />
            </div>}
            {messageSent&&!sending&&<div className=" p-4 w-full-h-full flex justify-center items-center">
              <h5 className=' text-center'>Thank You for getting in touch. One of our representatives will reach out to you as soon as possible.</h5>
            </div>}
            <div className='absolute top-0 right-0 pt-2 pr-2'>
              <CloseButton variant='white' onClick={()=>setRepairsTabOpen(false)}/>
            </div>
          </div>
        </div>}
        {rndTabOpen&&<div className=' flex justify-center w-screen '>
        <div className=' absolute  top-28 2xl:top-40 md:w-1/2 bg-black/30 text-white backdrop-blur shadow '>
          <h5 className='w-full text-center pt-3 border-b shadow-sm pb-1 '>R&D Projects</h5>
          {!messageSent&&!sending&&<div className="px-4 pt-2">
                  <h6>Tell us about your project:</h6>
                  <Form ref={rndForm}>
                  <Form.Control
                  type='textarea'
                  as={'textarea'}
                  value={moreInfo}
                  onChange={(e) => setMoreInfo(e.target.value)}
                  />
                  <h6>Your Contact Details:</h6>
                  <Form.Control
                    className='mb-3'
                    name='name'
                    placeholder='Name/Company Name'
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                  <Form.Control
                  className='mb-3'
                    name='email'
                    placeholder='Email'
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)}
                  />
                  <Form.Control
                    className='mb-3'
                    name='phone'
                    placeholder='Phone'
                    value={clientPhone}
                    onChange={(e) => setClientPhone(e.target.value)}
                  />
                  </Form>
                  <div className=' flex justify-end'>
                  <Button variant='outline-light' onClick={sendRnDReq} disabled={clientEmail!==''?false:true} className='justify-end mb-4' size='sm'>Get In Touch <span><i className="fi fi-rs-paper-plane"></i></span></Button>
                  
                  </div>
                  </div>}
                  {!messageSent&&sending&&<div className="w-full-h-full flex justify-center items-center">
                    <img src={loadingSVG} alt="" />
                  </div>}
            {messageSent&&!sending&&<div className=" p-4 w-full-h-full flex justify-center items-center">
              <h5 className=' text-center'>Thank You for getting in touch. One of our representatives will reach out to you as soon as possible.</h5>
            </div>}
                  <div className='absolute top-0 right-0 pt-3 pr-4'>
              <CloseButton variant='white' onClick={()=>setRnDTabOpen(false)}/>
            </div>
                </div>
          </div>}
      {!repairsTabOpen&&!rndTabOpen&&<div className=" relative w-screen md:h-screen flex justify-center pt-0 2xl:mt-20">
      <div className="md:flex px-2 py-4 w-10/12 justify-center gap-20 relative h-5/6 2xl:h-1/2 xl:gap-40">
          <div className='md:w-1/2 my-2 h-full rounded-md bg-white'>
            <img src={PCB} alt="" className=' rounded-t-md w-full md:h-1/2 object-cover'/>
            <div className="p-2">
              <h5>Research and Development</h5>
              <small>Welcome to Frantech, where innovation meets precision. We specialize in cutting-edge research and development of electrical and electronics equipment, pushing the boundaries of technology to create solutions that power a brighter, more connected future. <br /></small>
              <button className='mt-4 border shadow-black shadow p-3 rounded bottom-6' variant='outlined' onClick={()=>{
            setRnDTabOpen(true);
            setSending(false);
            setMessageSent(false)
            }}><h6 className='px-2 m-0 w-full h-full flex justify-center items-center text-center'>Tell Us About your Vision</h6></button>
            </div>
          </div>
          <div className='md:w-1/2 my-2 h-full rounded-md bg-white'>
            <img src={Repairs} alt="" className=' rounded-t-md w-full h-1/2 object-cover' />
            <div className="p-2 w-full h-fit">
              <h5>Electrical and Electronics Repairs</h5>
              <small>We are your trusted partner for electrical and electronics equipment repairs. With unrivaled expertise and a commitment to excellence, we breathe new life into your devices, ensuring they function flawlessly and stand the test of time. Your satisfaction, our priority. <br /></small>
              <button className='mt-4 border shadow-black shadow p-3 rounded bottom-6' onClick={()=>{
            setRepairsTabOpen(true);
            setSending(false);
            setMessageSent(false)
            }}>
              <h6 className='px-2 m-0 w-full h-full flex justify-center items-center text-center'>Tell Us About your Problem</h6></button>
            </div>
          </div>
        </div>
        </div>}
    </div>
  )
}

export default Services