import { Popover } from "react-bootstrap";

export const popoverTsogo = (
    <Popover id="popover-basic" className=" shadow">
      <Popover.Header as="h3" className=" bg-white">Tsogo Sun Gaming</Popover.Header>
      <Popover.Body>
        We <strong>Supply</strong> and <strong>Repair</strong> electronic components. <br/><hr/>
        <strong>Casinos:</strong>
        <ol>
          <li>- Silverstar Casino</li>
        </ol>
      </Popover.Body>
    </Popover>
);

export const popoverSunIntl = (
    <Popover id="popover-basic" className=" shadow">
      <Popover.Header as="h3" className=" bg-white">Sun International</Popover.Header>
      <Popover.Body>
        We <strong>Supply</strong> and <strong>Repair</strong> electronic components. <br/><hr/>
        <strong>Casinos:</strong>
        <ol>
          <li>- Sun City Casino</li>
          <li>- Meropa Casino</li>
          <li>- Wild Coast Casino</li>
        </ol>
      </Popover.Body>
    </Popover>
);

export const popoverHotslots = (
    <Popover id="popover-basic" className=" shadow">
      <Popover.Header as="h3" className=" bg-white">HotSlots</Popover.Header>
      <Popover.Body>
        We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
      </Popover.Body>
    </Popover>
);

export const popoverPeermont = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Peermont</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);

export const popoverSedibeng = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Sedibeng TVET College</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);

export const popoverElangeni = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Elangeni TVET College</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);

export const popoverBuffalo = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Buffalo City TVET College</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);

export const popoverThekwini = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Thekwini TVET College</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);

export const popoverMotheo = (
  <Popover id="popover-basic" className=" shadow">
    <Popover.Header as="h3" className=" bg-white">Motheo TVET College</Popover.Header>
    <Popover.Body>
      We <strong>Supply</strong> and <strong>Repair</strong> electronic components.
    </Popover.Body>
  </Popover>
);