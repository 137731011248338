import React, { useState } from 'react'
import Logo from '../images/frantech-logo-2.png'
import { Badge } from 'react-bootstrap';
import {Navbar,Collapse,Typography} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from 'react';


function NavList({cart, onLanding, currentPage, isMobile}) {
  
  return (
    <ul className="my-2 flex flex-col py-1 gap-2 items-center lg:mb-0 lg:mt-0 lg:flex-row lg:items-start lg:gap-6 rounded bg-white">
      
      {!onLanding&&
        <div>
          <Typography
            as="li"
            variant="paragraph"
            color="black"
            className="px-1 mt-1 font-medium h-full flex items-center"
          >
            <a href="/" className="flex items-center hover:text-blue-500 transition-colors  no-underline text-black ">
              Home
            </a>
          </Typography>
        </div>
      }
      <Typography
        as="li"
        variant="paragraph"
        color="blue-gray"
        className="px-1 mt-1 font-medium h-full flex items-center"
        style={currentPage==='products'?{border: '1px solid black', borderRadius:5, boxShadow:'2px 2px 4px #94a3b8', backgroundColor:'#f1f5f9'}:{}}
      >
        <a href="/products" className="flex items-center hover:text-blue-500 transition-colors no-underline text-black ">
          Products
        </a>
      </Typography>
      <Typography
        as="li"
        variant="paragraph"
        color="blue-gray"
        className="px-1 mt-1 font-medium"
        style={currentPage==='services'?{border: '1px solid black', borderRadius:5, boxShadow:'2px 2px 4px #94a3b8', backgroundColor:'#f1f5f9'}:{}}
      >
        <a href="/services" className="flex items-center hover:text-blue-500 transition-colors no-underline text-black ">
          Services
        </a>
      </Typography>
      
      

      {onLanding&&<div className='flex items-center'>
        <div  className='flex items-center    ' style={isMobile?{flexDirection:'column',gap:'6px'}:{gap:'6px'}}>
          <Typography
        as="li"
        variant="paragraph"
        color="black"
        className="px-1 mt-1 font-medium"
      >
        <a href="/#about" className="flex items-center hover:text-blue-500 transition-colors  no-underline text-black ">
          About
        </a>
      </Typography>
      <Typography
        as="li"
        variant="paragraph"
        color="blue-gray"
        className="px-1 mt-1 font-medium"
      >
        <a href="/#portfolio" className="flex items-center hover:text-blue-500 transition-colors  no-underline text-black ">
          Portfolio
        </a>
      </Typography>
      <Typography
        as="li"
        variant="paragraph"
        color="blue-gray"
        className="px-1 mt-1 font-medium"
      >
        <a href="/#contact" className="flex items-center hover:text-blue-500 transition-colors  no-underline text-black ">
          Contact
        </a>
      </Typography>
      </div>
      </div>
      }

{cart.length!==0&&<Typography
        as="li"
        variant="paragraph"
        color="blue-gray"
        className="px-1 mb-1 mt-0.5 font-medium"
        style={currentPage==='cart'?{border: '1px solid black', borderRadius:5, boxShadow:'2px 2px 4px #94a3b8', backgroundColor:'#f1f5f9'}:{}}
      >
        <a href="/cart" className="flex items-center hover:text-blue-500 transition-colors no-underline text-black ">
          RFQ <span className='pl-1 mb-1'><Badge bg="danger">{cart.length}<span className='pl-1'><small>item{cart.length!==1&&<span>s</span>}</small></span></Badge></span>
        </a>
      </Typography>}
    </ul>
  );
}



function Navigationbar({cart,onLanding,currentPage}) {

  const [openNav, setOpenNav] = useState(false);
  
  useEffect(()=>{
    console.log('On Landing',onLanding)
    
  },[onLanding])
  
 
  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);
 
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
 
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
 
  return (
    <Navbar color='white' blurred className="mx-auto w-screen px-16 py-1 fixed top-0 z-50 h-16">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 text-black flex "
        >
          <img className=' h-12' src={Logo} alt="" srcSet="" />
          
        </Typography>
        
        <div className="hidden lg:block">
          <NavList cart={cart} onLanding={onLanding} currentPage={currentPage} isMobile={openNav}/>
        </div>
        <button className=" text-black hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden  items-center" onClick={() => setOpenNav(!openNav)}>
          {openNav ? (
            <div>
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            
            </div>
            ):(
              <div>
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            {cart.length!==0&&
              <Badge bg='danger' className='absolute top-4'>{cart.length}</Badge>}
            </div>
          )}
        </button>
      </div>
      <Collapse open={openNav}>
        <NavList cart={cart} onLanding={onLanding} currentPage={currentPage} isMobile={openNav}/>
      </Collapse>
    </Navbar>
  );
}

export default Navigationbar