import './App.css';
import Navigationbar from './components/Navbar';
import Products from './pages/Products';
import Landing from './pages/Landing';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import Cart from './pages/Cart';
import { useLocalStorage } from 'usehooks-ts'
import Services from './pages/Services';
import { useState } from 'react';






function App() {
  const [cart, updateCart] = useLocalStorage('carts',[]);
  const [onLandingPage, setOnLandingPage] = useState(true)
  const [currentPage,setCurrentPage] = useState('')

  return (
    <div className='relative min-h-screen ease-in duration-300'>
      <BrowserRouter>
        <Navigationbar cart = {cart} onLanding={onLandingPage} currentPage={currentPage}/>
          <Routes>
            <Route path='/' element={<Landing setLanding = {setOnLandingPage} />}/>
            <Route path='/products' element={<Products cart = {cart} updateCart = {updateCart} setCurrentPage={setCurrentPage} setLanding={setOnLandingPage} />}/>
            <Route path='/services' element={<Services setCurrentPage={setCurrentPage} setLanding={setOnLandingPage}/>}/>
            <Route path='/cart' element={<Cart cart = {cart} updateCart = {updateCart} setCurrentPage={setCurrentPage} setLanding={setOnLandingPage}/>}/>
          </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
