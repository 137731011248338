import React from 'react'

function ProductCategories({category, setCategory, dbCategory}) {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Use smooth scrolling
    });
  };


    function getCategoryAbbreviation(categoryTitle) {
        const categoryMap = {
          'Electronics Components': 'components',
          'Gaming and Computers': 'gaming',
          'Tools and Instruments': 'tools',
          'Robotics': 'robotics',
          'Electrical': 'electrical',
          'Miscellaneous': 'miscellaneous'
          
        }; //Define category title and link to corresponding name of the category in the DB
      
        return categoryMap[categoryTitle] || 'Unknown'; // Return 'Unknown' for unsupported categories
      }

    function handleClick () {
        setCategory(category.title);
        dbCategory([getCategoryAbbreviation(category.title)]);
        scrollToTop();
    }
  return (
    <div onClick={handleClick} className=' p-3 m-3 hover:cursor-pointer hover:scale-110 ease-in duration-300 rounded shadow items-center w-5/6'>
        <img className=' h-56 w-full object-cover' src={category.thumbnail} alt="" />
        <h5 className=' pt-3'>{category.title}</h5>
    </div>
  )
}

export default ProductCategories