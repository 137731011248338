import { cloudDB } from "../pages/Products";



export function searchProducts(query) {
  const searchTerms = query.toLowerCase().split(' '); // Split query into individual words and convert to lowercase
  const matchingProducts = [];
  for (const term of searchTerms) {
    const termMatches = cloudDB.filter(product => product.productName.toLowerCase().includes(term));
    matchingProducts.push(...termMatches);
  }
  // Remove duplicate products
  return [...new Set(matchingProducts)];
}

export function filterProducts(query) {   
  const matchingProducts = [];  
  for (const term of query) {
    const termMatches = cloudDB.filter(product => product.category.toLowerCase().includes(term));
    matchingProducts.push(...termMatches);
  }
  // Remove duplicate products
  return [...new Set(matchingProducts)];
}