import { useState } from 'react';

const usePagination = (itemsPerPage, initialPage = 1) => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginate = (array) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  };

  return {
    currentPage,
    goToPage,
    paginate,
  };
  
};

export default usePagination;