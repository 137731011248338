import React from 'react'
import { useState } from 'react';
import { updateQuantity } from '../constants/ConstantVariable';


function CartTemplate({data,index,cart,updateCart}) {

  let [inputValue,setInputValue] = useState(data.quantity);
  
 
  function increment () {
    setInputValue(++inputValue);
    updateCart(updateQuantity(cart,data.productName,inputValue));
  }

  function decrement() {
    if(inputValue>0){
      setInputValue(inputValue-1);
      updateCart(updateQuantity(cart,data.productName,inputValue-1));
    }
  }

  function deleteItem () {
    const objectToDelete = data;
    const newCart = cart.filter(obj => obj !== objectToDelete)
    updateCart(newCart)
  }

    
  return (
    <div>
      <div className='card rounded-3 mb-4 h-48 md:mx-16 shadow-sm'>
        <div className='card-body p-4'>
          <div className='row d-flex justify-between items-center'>
            <div className="col-md-2 col-lg-2 col-xl-2 d-flex">
              <h6 className=' items-center justify-center p-8'>{index+1}</h6>
              <img className='w-24 h-24 object-contain' src={data.productImage} alt="" />
            </div>
            <div className='col-md-3 col-lg-3 col-xl-3'>
              <h6>{data.productName}</h6>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
              {inputValue===1?(
                <button className='btn btn-link px-2' onClick={deleteItem}>
                  <i className="fas fa-trash fa-lg text-red-600"></i>
                </button>
                ):(
                <button className='btn btn-link px-2' onClick={decrement}>
                  <i className="fas fa-minus"></i>
                </button>
              )}
              <input id="form1" min="2" name="quantity"  value={inputValue} onChange={(val) => {setInputValue(val.target.value);
              updateCart(updateQuantity(cart,data.productName,val.target.value));
              }} type="number" className="form-control form-control-sm text-center" />
              <button className="btn btn-link px-2" onClick={increment}>
                <i className="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartTemplate