export function addObjectKey(obj, key, value) {
    obj[key] = value;
    return obj;
  };

export function updateQuantity(objectsArray, keyAValue, newDValue) {
    return objectsArray.map(obj => {
      if (obj.productName === keyAValue) {
        return { ...obj, quantity: newDValue };
      }
      return obj;
    });
  }

export function handleNavBg (setNavBg,navBg) {
    // Check the current scroll position
    if(window.scrollY <= 50){
      setNavBg('transparent')
    }
    else{
      setNavBg('light')
    } 
  };