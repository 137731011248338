// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNxlJu_JjnM-GW1gIesNchF6tf14F7Cfw",
  authDomain: "frantech-site.firebaseapp.com",
  projectId: "frantech-site",
  storageBucket: "frantech-site.appspot.com",
  messagingSenderId: "425077519456",
  appId: "1:425077519456:web:8cc7f4f3d4ffdfe1db7f05",
  measurementId: "G-PDVMYF8R9R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);