import React, {  useRef, useState } from 'react'
import 'react-slideshow-image/dist/styles.css';
import LandingVid from '../videos/video1.mp4'
import mapBg from '../images/map.svg'
import {  Button, Card, Carousel, Form } from 'react-bootstrap';
import RepairLady from '../images/about.jpg'
import { useNavigate } from 'react-router';
import PCB from '../images/pcb1.jpg'
import Repairs from '../images/wallpaperflare.com_wallpaper.jpg'
import Comps from '../images/g1.jpg'
import emailjs from '@emailjs/browser'

import loadingSVG from '../images/preloader.svg'
import { useEffect } from 'react';
import ClientsCarousel from '../components/ClientsCarousel';








function Landing({setLanding}) {

  const [clientName,setClientName] = useState('');
  const [clientEmail,setClientEmail] = useState('');
  const [clientPhone,setClientPhone] = useState('');
  const [clientMessage,setClientMessage] = useState('');
  const [sendSuccess,setSendSuccess] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [sending,setSending] = useState(false);

  const form = useRef();

  useEffect(() => {
    setLanding(true)
  },[setLanding])

  const navigate = useNavigate();
  const navigateToProducts = () => {
    navigate('/products')
  }

  const navigateToServices = () => {
    navigate('/services')
  }

  function bundleEmail () {
    setSending(true);
    emailjs.sendForm('service_vmn997o', 'template_pcoh6bs', form.current, 'POCawG94LKdoqfk7M')
    .then((result) => {
      console.log(result.text);
      setSendSuccess(true);
      setSending(false);
    }, 
    (error) => {
      console.log(error.text);
    });
  }

  // Event handler to track when the video has loaded
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div id='home' className=' bg-slate-100 w-screen relative'>
      {!videoLoaded&&
        <div className=' w-full h-screen flex items-center justify-center'>
          <img src={loadingSVG} alt="" srcSet="" />
        </div>
      }
      <video autoPlay loop muted className='w-100 h-screen object-cover' src={LandingVid} onLoadedData={handleVideoLoad}/>
      {videoLoaded&&
        <div>  
         <div className="absolute w-full h-3/4 top-14 ">
            <Carousel>
              <Carousel.Item interval={3000}>
                <div className='h-screen md:w-screen flex items-center justify-center'>
                  <div>
                    <h4 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>Welcome to <span className=' text-cyan-300'>Frantech Electronics</span></h4>
                    <h1 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>Leading Electronics and Equipment Supplier</h1>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className='h-screen  md:w-screen flex items-center justify-center'>
                  <div>
                    <h4 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>Include more sales </h4>
                    <h1 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>Make Unique <span className=' text-cyan-300'>Planning</span> for your Business</h1>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className='h-screen md:w-screen flex items-center justify-center'>
                  <div>
                    <h4 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>More convinient than others </h4>
                    <h1 style={{textShadow:'2px 4px 4px #000000'}} className='text-white text-center'>Find Value and <span className=' text-cyan-300'>Build</span> some Confidence</h1>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        
          {/**Introduction */}
          <div id='about' className=' rounded-md shadow-black relative mx-4 sm:mx-32 pt-20 pb-48'>
            {/**Introduction --About */}
            <div className=" bg-white shadow-black shadow md:flex p-4 rounded">
              <div className=' md:w-1/2'>
                <h4 className=' text-cyan-900'>ABOUT US</h4>
                <h3>We have 10+ Years of Experience In Development & Supply Of Electronic Equipments.</h3>
                <hr className=' w-1/2 border-y-2 border-black'/>
                <p className='text-black'>Established in 2013, Frantech Electronics initially provided electrical installation services and naturally grew since. As more talented members joined our Team, we grew into an inclusive electronics solutions provider with our competencies covering a wide range of subsectors in electronics.
                </p>
                <div className="flex pb-2">
                {/**phone */}
                </div>
              </div>
              <div className=' md:w-1/2 flex justify-end'>
                <img className=' w-96' src={RepairLady} alt="" srcSet="" />
              </div>

              {/**Mission/Vision Cards */}
              <div className='  md:flex hidden absolute bottom-0 mb-4 w-full h-2/5 gap-8 items-center justify-center'>
                <Card border='info' className='shadow-black shadow w-1/4 hover:scale-110 ease-in duration-300 hover:cursor-pointer'>
                  <Card.Body className='text-center'>
                    <Card.Header className=' bg-white'>
                      <i className="fi fi-rr-calendar-lines-pen text-cyan-600 text-3xl"></i>
                      <h5 className=' text-cyan-600 font-sans subpixel-antialiased mt-1'>Vision</h5>
                    </Card.Header>
                    <Card.Title></Card.Title>
                    <Card.Text>Our vision is to become a world class electrical and electronics engineering company delivering quality supplies, designs and products beyond client expectations.</Card.Text>
                  </Card.Body>
                </Card>
                <Card border='info' className='shadow-black shadow w-1/4 hover:scale-110 ease-in duration-300 hover:cursor-pointer'>
                  <Card.Body className='text-center'>
                    <Card.Header className=' bg-white'>
                      <i className="fi fi-rr-chart-line-up text-cyan-600 text-3xl"></i>
                      <h5 className=' text-cyan-600 font-sans subpixel-antialiased mt-2'>Mission</h5>
                    </Card.Header>
                    <Card.Text>We make it our mission to learn, innovate, equip and reinvent ourselves everyday in order to stay relevant and competetive for the benefit of our clients.</Card.Text>
                  </Card.Body>
                </Card>
                <Card border='info' className='shadow-black shadow w-1/4 bg-white hover:scale-110 ease-in duration-300 hover:cursor-pointer'>
                  <Card.Body className='text-center'>
                    <Card.Header className=' bg-white'>
                      <i className="fi fi-rr-chart-user text-cyan-600 text-3xl"></i>
                      <h5 className=' text-cyan-600 font-sans subpixel-antialiased mt-2'>Keys for Development</h5>
                    </Card.Header>
                    <Card.Text>We believe in professionalism and value towards our clients, motivation, excellence, trust, innovation, transparency and leadership in our organisation.</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              {/**End of Mission/Vision */}
            </div>
            {/**End of Introduction --About */}
          </div>
          {/**End of Introduction */}


          {/**Introduction --Services */}
          <div id='portfolio' className='w-screen relative pb-64 2xl:pb-96 lg:pb-64 pt-16'>
            <div className=' bg-black  text-center text-white pb-14 h-56 '>
              <h3 className=' text-slate-100 text-center pt-4'>Our Services</h3>
              <p className=' md:mx-20'>Our experience in the last decade, the high quality electrical supplies we have delivered to our clients, designs and products we have engineered, the client relations we have built, customer care we have given and our hard work are what we pride ourselves in. We only deliver the best.</p>
              <div className='  md:flex hidden absolute bottom-0 mb-4 w-full gap-8 items-center justify-center'>
                <Card bg='light' border='info' className='shadow-black shadow w-1/4  hover:scale-110 ease-in duration-300 hover:cursor-pointer' >
                  <Card.Body className='text-center'>
                    <Card.Header className=''><h5 className=' text-blue-400 font-sans subpixel-antialiased' onClick={navigateToProducts}>Supply of Electronics and Robotics components</h5></Card.Header>
                    <Card.Title></Card.Title>
                    <Card.Text className=' text-white'>
                      <img src={PCB} alt="" srcSet="" className=' rounded-b'/>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg='light' border='info' className='shadow-white shadow w-1/4  hover:scale-110 ease-in duration-300 hover:cursor-pointer'>
                  <Card.Body className='text-center'>
                    <Card.Header><h5 className=' text-blue-400 font-sans subpixel-antialiased' onClick={navigateToServices}>Repairs</h5></Card.Header>
                    <Card.Title></Card.Title>
                    <Card.Text className=' text-white'>
                      <img src={Repairs} alt="" srcSet=""  className=' rounded-b'/>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg='light' border='info' className='shadow-white shadow w-1/4  hover:scale-110 ease-in duration-300 hover:cursor-pointer'>
                  <Card.Body className='text-center'>
                    <Card.Header><h5 className=' text-blue-400 font-sans subpixel-antialiased'>...And Everything Inbetween</h5></Card.Header>
                    <Card.Title></Card.Title>
                    <Card.Text className=' text-white'>
                      <img src={Comps} alt="" srcSet=""  className=' rounded-b'/>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          {/**End of Introduction --Services */}

          {/**Clients */}
          <div id='client' className=' w-full pt-16 md:px-16 z-40 relative md:items-center md:justify-center '>
            <div className='bg-white shadow pt-4 rounded'>
            <div className=' px-4 text-center '>
              <h4>Current Clientelle</h4>
              <hr />
              <p>Our clients come first. We care about their needs and value their opinions. We continue to form strong working relationships and bond with each and every one of our clients.</p>
            </div>
            <div className="md:px-4 pl-2">
              <ClientsCarousel/>
            </div>
            </div>
          </div>

          {/**Contact us/Request Call back */}
          <div id='contact' style={{backgroundImage: `url(${mapBg})`}} className=' min-h-screen bg-cover md:flex gap-10 md:px-16 pb-4 pt-20'>
            {/**addresses and contacts */}
            <div className=' px-4'>
              {/**location */}
              <h5 className=''>Office Location</h5>
              <hr />
              <div className=' flex gap-10'>
                <i className="fi fi-sr-map text-cyan-600 text-4xl"></i>
                <address>Unit B19 Leogem Innovation Worx <br />88 Richards Drive <br />Halfway House <br />Midrand <br />1685</address>
              </div>
              {/**Hours */}
              <h5 className=''>Office Hours</h5>
              <hr />
              <div className=' flex gap-10'>
                <i className="fi fi-sr-time-forward text-cyan-600 text-4xl"></i>
                <p>Mon - Fri 07:00 - 17:00 <br />Saturday 09:00 - 14:00</p>
              </div>
              {/**Phones */}
              <h5 className=''>Phone</h5>
              <hr />
              <div className=' flex gap-10 items-center'>
                <i className="fi fi-sr-phone-call text-cyan-600 text-4xl"></i>
                <p>+27 10 447 1206 <br /></p>
              </div>
              {/**Email */}
              <h5 className='mt-2'>Email</h5>
              <hr />
              <div className=' flex gap-10'>
                <i className="fi fi-sr-envelope text-cyan-600 text-4xl"></i>
                <p> admin@frantechelectronics.co.za <br />sales@frantechelectronics.co.za</p>
                
              </div>
            </div>
            {/**Contact Form */}
            {sendSuccess?(
            <div className=' text-center md:w-3/4 justify-center flex'>
              <h3 className=' text-green-800'><span><i class="fi fi-rs-paper-plane"></i></span>  Message Sent! </h3>
            </div>
            ):(
            <div className=' flex items-center justify-center md:w-3/4 px-4'>
              {!sending&&<div className=' w-full'>
              <h3>Leave Us a Message</h3>
              <Form ref={form}>
                <Form.Control 
                  className='mb-2'
                  placeholder='Name'
                  type='name'
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  name='client_Name'
                />
          
                <Form.Control 
                  className='mb-2'
                  placeholder='Email*'
                  type='email'
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  name='client_Email'
                />
          
                <Form.Control 
                  className='mb-2'
                  placeholder='Phone'
                  type='phone'
                  value={clientPhone}
                  onChange={(e) => setClientPhone(e.target.value)}
                  name='phone'
                />
          
                <Form.Control 
                  className='mb-2'
                  placeholder='Message*'
                  as={'textarea'}
                  rows={6}
                  value={clientMessage}
                  onChange={(e) => setClientMessage(e.target.value)}
                  name='message'
                />
              </Form>
          
              <Button className='text-white mb-3' color='red' variant='info' onClick={bundleEmail} disabled={clientEmail!==''?false:true}>
                Send Message 
                <span><i className="fi fi-rs-paper-plane"></i></span>
              </Button>
              </div>}
              {sending&&<div className='w-full h-full flex items-center justify-center'><img src={loadingSVG} alt="" srcSet="" /></div>}
            </div>)}
          </div>
      
          {/**End of Contact Us */}
        </div>
      } 
    </div>
  )
}

export default Landing