import React, {  useEffect, useState } from 'react'
import { Badge, Button, Modal } from 'react-bootstrap';
import { addObjectKey } from '../constants/ConstantVariable';
import { Helmet } from 'react-helmet-async';

function ProductTemplate ({data,cart,addTocart})  {
    
    const productName = data.productName;
    const productImage = data.productImage;
    const productDescription = data.productDescription;
    const productDatasheet = data.datasheet;
    const stock = data.stock;
    const [modalShow,setModalShow] = useState(false);
    const [itemCount,setItemCount] = useState()
    
  
    

    const addToCartFunc = () => {
      //add item to RFQ cart.
      const cartData = addObjectKey(data,"quantity",1)
      addTocart(current => [...current,cartData]);
      setModalShow(false);
    }


    
    function isItemInCart(array,key,value) {
      //check if item is already in RFQ cart.
      return array.some(obj => obj[key] === value);
    }

    function deleteItem (cart, nameToRemove) {
      const newCart = cart.filter(tag => tag.productName !== nameToRemove);
      addTocart(newCart)
    }

    function Increment () {
      const updateQuantity = cart.map((product) => {
        if(product.productName === productName){
          var quantity = product.quantity;
          quantity++;
          return { ...product, quantity: quantity };
        }
        
        //addTocart(product)
        return product;
      })
      addTocart(updateQuantity)
    }

    function Decrement () {
      const updateQuantity = cart.map((product) => {
        if(product.productName === productName){
          var quantity = product.quantity;
          quantity--;
          return { ...product, quantity: quantity };
        }
        return product;
      })
      addTocart(updateQuantity)
    }

    


    function findQuantityByName(items, itemName) {
      const item = items.find(item => item.productName === itemName);
      return item ? item.quantity : 0;
    }

    
    

    useEffect(()=>{
      setItemCount(findQuantityByName(cart, productName))
    },[cart,productName])
    
    
    function MyVerticallyCenteredModal(props) {
      //Popup modal showing more product info... product description and the likes
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{productName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex gap-10 items-center">
              <Helmet>
                <title>{productName}</title>
                <meta name='description' content={productDescription} />
              </Helmet>
              <div className=' w-1/3 max-w-[240px] overflow-hidden'>
                <img src={productImage} alt="" srcset="" className=' hover:cursor-pointer  hover:scale-125 object-cover' />
                <small className=' text-xs'>Image for representation purpose only.</small>
              </div>
              <div className=' w-2/3'>
                <h4>Description</h4>
                <p>{productDescription}</p>
                <p style={stock<=10?{color:'red'}:{color:'green'}}>{stock!==0&&<p>In Stock: {stock}</p>} {stock===0&&<p></p>}</p>
                {productDatasheet===''?(<div></div>):
               (<div className=' m-2 flex items-center'>
                  <i className="fi fi-sr-file-pdf text-red-600 mr-2"></i>
                  <a href={productDatasheet} target="_blank" rel="noopener noreferrer">Datasheet</a>
                
                </div>)
                }
                
                <Button onClick={addToCartFunc} disabled = {isItemInCart(cart,"productName",productName)?true:false}>{isItemInCart(cart,"productName",productName)?'Item in RFQ':'Add to RFQ'}</Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
    }
  return (
    <div className=' rounded relative border w-full h-fit m-4 p-2 shadow items-center bg-white'>
      <div className=' absolute top-2 right-2 animate-bounce'>{isItemInCart(cart,"productName",productName)&&<p className=' text-lg'><Badge className='shadow' bg='danger' pill>{itemCount}</Badge></p>}</div>
      <div className=' absolute bottom-5 hover:cursor-pointer left-3'>{itemCount>=2&&<i onClick={()=>deleteItem(cart,productName)} className="fas fa-trash fa-lg text-red-600"></i>}</div>
      <div className='w-full flex items-center justify-center rounded p-2 '>
        <img onClick={() => {
              setModalShow(true)}} className=' hover:cursor-pointer w-40 h-40 object-contain rounded-md' src={productImage} alt=''/>
      </div>
      <div className='text-center w-full text-back grid grid-rows-3 h-32 justify-center items-center '>
        <p className=' hover:cursor-pointer hover:drop-shadow-lg' onClick={() => {
              setModalShow(true)}}><b>{productName}</b></p>
        <p style={stock<=10?{color:'red'}:{color:'green'}}>{stock>=10&&<p>In Stock!</p>} {stock<10&&stock!==0&&<p>In Stock: {stock}</p>} {stock===0&&<p></p>}</p>
        <div className='flex gap-1 justify-center'>
          <div>
            {!isItemInCart(cart,"productName",productName)&&<Button className=' w-fit' variant='danger' onClick={addToCartFunc} disabled = {isItemInCart(cart,"productName",productName)?true:false} >
              {<p className='p-0 m-0'>Add To RFQ</p>}
              
            </Button>}
          </div>
          {isItemInCart(cart,"productName",productName)&&<div className=' flex gap-2 border-2 rounded-lg px-1 border-blue-400 shadow'>
          <div>
            <button className='btn btn-link px-2'  >
                  {itemCount===1?
                  (<i onClick={()=>deleteItem(cart,productName)} className="fas fa-trash fa-lg text-red-600"></i>):(<i className="fas fa-minus text-red-600" onClick={()=>Decrement()}></i>)}
                  
                </button>
          </div>
          <div>
            <button className='btn btn-link px-2' onClick={()=>Increment()} >
                  <i className="fas fa-plus"></i>
                </button>
          </div>
          </div>}
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        
    </div>
  )
}

export default ProductTemplate