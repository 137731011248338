
import React, { useEffect } from 'react'
import { Button, CloseButton, Form } from 'react-bootstrap'
import { renderToString } from 'react-dom/server';
import loadingSVG from '../images/preloader.svg'

import CartTemplate from '../components/CartTemplate';
import { useState } from 'react';
import emailjs from '@emailjs/browser'
import { Helmet } from 'react-helmet-async';

function Cart({cart,updateCart,setLanding,setCurrentPage}) {
  const [showModal,setShowModal] = useState(false);
  const [clientName,setClientName] = useState('');
  const [client_Email,setClientEmail] = useState('');
  const [clientPhone,setClientPhone] = useState('');
  const [RFQObject,setRFQObject] = useState([]);
  const [RFQItems,setRFQItems] = useState([]);
  const [RFQSent,setRFQSent] = useState(false);
  const [sending,setSending] = useState(false)

  useEffect(() => {
    setLanding(false);
    setCurrentPage('cart')
  },[setLanding,setCurrentPage])

  useEffect(()=>{
    setRFQItems([])
    cart.forEach((item) => {
      const cartItem = {
        itemName: item.productName,
        itemQuantity: item.quantity,
      }
      setRFQItems(current => [...current,cartItem])
    })
  },[cart])

  useEffect(()=>{
    setRFQObject(
      {
        name: clientName,
        email: client_Email,
        phone: clientPhone,
        items: RFQItems
      }
    );
  },[clientName,client_Email,clientPhone,RFQItems])

  function sendRFQPopup() {
    function sendRFQ(){
      const finalRFQHtml = generateHtmlString(RFQObject)
      setSending(true);
      const templateParams = { //wrap the RFQHtml in an object before passing it to emailjs
        the_html: finalRFQHtml,
      }

      emailjs.send('service_vmn997o', 'template_pj42i4a', templateParams, 'POCawG94LKdoqfk7M')
      .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
       setRFQSent(true);
       setSending(false);
       clearRFQ();
      }, 
      function(error) {
       console.log('FAILED...', error);
      });
    }

    /*function totalItems (){
      var itemsCount = 0;
      cart.forEach((item) => {
        itemsCount = itemsCount + item.quantity;
      });
      return (
        <span>
          <p><strong>Total Items: {itemsCount}</strong></p>
        </span>  
      );
    }*/


    return (
      <div className=' absolute p-8 border rounded-md z-50 shadow bg-slate-50 top-16 md:left-1/3 md:w-1/3'>
        <h5>Send RFQ</h5>
        <hr />
        <h6>Please enter your contact details:</h6>
        <div className=' absolute top-8 right-8'>
          <CloseButton onClick={() => setShowModal(false)}/>
        </div>
        {sending&&
          <div className=' w-full h-full flex items-center justify-center'>
            <img src={loadingSVG} alt="Loading" />
          </div>
        }
        {!sending&&RFQSent&&
          <div className=' w-full h-full flex items-center justify-center'>
            <h4 className='text-cyan-600'>RFQ Sent!</h4>
          </div>
        }
        {!RFQSent&&!sending&&
          <div>
            <Form.Control 
              className='my-2'
              placeholder='Name'
              type='name'
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              name='client_Name'
            />
            <Form.Control 
              className='mb-2'
              placeholder='Email*'
              type='email'
              value={client_Email}
              onChange={(e) => setClientEmail(e.target.value)}
              name='client_Email'
              id='txtEmail'
              required
            />
            
            <Form.Control 
              className='mb-2'
              placeholder='Phone'
              type='tel'
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={clientPhone}
              onChange={(e) => setClientPhone(e.target.value)}
              name='client_Phone'
            />
          
            <div>
              
            </div>
        
            <div className="flex gap-10 justify-end pt-2">
              <Button onClick={sendRFQ} disabled={client_Email!==''?false:true}>Send RFQ</Button>
            </div>
          </div>
        }
      </div>  
    );
  }

  function clearRFQ () {
    updateCart([])
  }

  function generateHtmlString(data) {
    const { name, email, phone } = data;
    
  
    const itemsHtml = cart.map((item, index) => (
      //Tabulate the products in the RFQ cart before sending them via email
      <tr key={index}>
        <td>{index+1}.</td>
        <td >{item.productName}</td>
        <td>{item.quantity}</td> 
      </tr>
    ));
    
    const itemsHtmlString = renderToString(itemsHtml);
    const htmlString = `
      <style>
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
        }
      </style>
      <div>
        <h2>New RFQ</h2>
        <h3>Name: ${name}</h3>
        <h4>Email: ${email}</h4>
        <h4>Phone: ${phone}</h4>
        <table>
          <tr>
            <th>#</th>
            <th>Item Name</th>
            <th>Quantity</th>
          </tr>
          ${itemsHtmlString}
        </table>
      </div>
    `;
  
    return htmlString;
  }
 
  return (
    <div className=' min-h-screen pt-16 mb:px-16 px-4 mb-8 w-screen'>
      <Helmet>
        <title>Frantech - RFQ Cart</title>
      </Helmet>
      <div className=' flex-row'>
        {cart.length!==0&&
          <div className=' mb-8 sticky top-14 z-30 w-full bg-white'>
            <h4>Current Cart</h4> 
            <hr />
            {/**Display the send RFQ Popup */}
            {showModal&&sendRFQPopup()}
          </div>
        }
        {cart.map((product,index) => (
          //Display all products in the RFQ cart 
          <CartTemplate data={product} index={index} cart = {cart} updateCart = {updateCart}/>
        ))}
      </div>
      <hr />
      {cart.length!==0?(
        <div className="flex w-full justify-end gap-10">
          <div className='justify-start'>
            <Button onClick={clearRFQ}>Clear RFQ {showModal}</Button>
          </div>
          <div className="justify-end">
            <Button variant='danger' onClick={() => setShowModal(true)}>Generate RFQ</Button>
          </div>
        </div>
        ):(
        <div className=' w-full flex-row justify-center '>
          <h6 className='text-center'>Cart Empty!</h6>
          <p className='text-center'>Please go to <a href="/products">products</a> to select items.</p>
        </div>
      )}
      
    </div>
    
  )
}

export default Cart