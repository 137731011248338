import React, { useEffect, useState } from 'react'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, CloseButton, Dropdown } from 'react-bootstrap';
import { filterProducts, searchProducts } from '../constants/SearchProducts';
import { db} from '../configs/firebase';
import { collection, getDocs } from 'firebase/firestore';
import loadingSVG from '../images/preloader.svg'
import emailjs from '@emailjs/browser'
import toolsNinstrumentsThumbnail from '../images/g4.jpg'
import roboticsThumbnail from '../images/g1.jpg'
import componentsThumbnail from '../images/electronic-components.jpg'
import gamingThumbnail from '../images/gaming.webp'
//import microcontrollerThumbnail from '../images/microcontroller.jpg'
import ProductCategories from '../components/ProductCategories';
import MiscellaneousThumbnail from '../images/electrical miscelleneous.jpg'
import ElectricalThumbnail from '../images/electrical category.jpg'
import { Helmet } from 'react-helmet-async';
import PaginateItems from '../components/PaginateItems';
export let cloudDB = [];


function Products({cart,updateCart,setCurrentPage,setLanding}) {
  const [searchText,setSearchText] = useState('');
  const [enquireProduct,setEnquireProduct] = useState(false);
  const [clientName,setClientName] = useState('');
  const [client_Email,setClientEmail] = useState('');
  const [clientPhone,setClientPhone] = useState('');
  const [moreInfo,setMoreInfo] = useState('');
  const [messageSent,setMessageSent] = useState(false)
  const [sending,setSending] = useState(false);
  const [gamingChecked,setGamingChecked] = useState(false);
  const [toolsChecked,setToolsChecked] = useState(false);
  const [roboticsChecked,setRoboticsChecked] = useState(false);
  const [componentsChecked,setComponentsChecked] = useState(false);
  const [microcontrollersChecked,setMicrocontrollersChecked] = useState(false);
  const [filtercategoryegories,setFiltercategoryegories] = useState([])
  const [categoryOpen,setCategoryOpen] = useState('')
  const [dbCategory,setDBCategory] = useState('')
  const [isBusy,setIsBusy] = useState(true)
  //const [itemsPerPage,setItemsPerPage] = useState(12)

  useEffect(() => {
    //load all products from the database
    setLanding(false);
    
    setCurrentPage('products');
    const cloudData = [];
    async function getProducts() {
      const querySnapshot = await getDocs(collection(db, "allProducts"));
      querySnapshot.forEach((doc) => {
        cloudData.push(doc.data())
      }); 
      setIsBusy(false)
      //export all products to the search and filter functions in constants/SearchProducts.js
      cloudDB = cloudData;
    }
    getProducts();
  },[setLanding,setCurrentPage]);

  /*useEffect(() => {
    //load highlight products for gaming
    const cloudData = [];
    async function getProducts() {
      const querySnapshot = await getDocs(collection(db, "gamingProducts"));
      querySnapshot.forEach((doc) => {
        cloudData.push(doc.data())
      });
      setGamingProducts(cloudData);
      setIsBusy(false);
      
    }
    getProducts();
  },[]);

  useEffect(() => {
    //load highlight products for intruments and tools
    const cloudData = [];
    async function getProducts() {
      const querySnapshot = await getDocs(collection(db, "instrumentProducts"));
      querySnapshot.forEach((doc) => {
        cloudData.push(doc.data())
      });
      setInstrumentProducts(cloudData);
      setIsBusy(false);
      
    }
    getProducts();
  },[]);

  useEffect(() => {
    //load highlight products for robotics
    const cloudData = [];
    async function getProducts() {
      const querySnapshot = await getDocs(collection(db, "roboticsProducts"));
      querySnapshot.forEach((doc) => {
        cloudData.push(doc.data())
      });
      setRoboticsProducts(cloudData);
      setIsBusy(false);
      
    }
    getProducts();
  },[]);*/


  function sendEnquiry(){
    //Send email enquiring about product not found in search
    const htmlString = `
      <style>
        div.moreInfo {
          border-style: solid;
          padding: 2px;
        }
      </style>
      <h2>Product Enquiry</h2>
      <h3>Client Name: ${clientName}</h3>
      <h4>Client Email: ${client_Email}</h4>
      <h4>Client Phone: ${clientPhone}</h4>
      <div class = 'moreInfo'>
      <h5>Product:</h5> 
      <p>${moreInfo}</p>
      </div>
    `
    const templateParams = {
      the_html: htmlString,
    }
    setSending(true) //start the loading icon while email is being sent
    emailjs.send('service_vmn997o', 'template_pj42i4a', templateParams, 'POCawG94LKdoqfk7M')
    .then(() => {
      setMessageSent(true)
      setSending(false) //stop the loading icon after successfully sending email
    })
  }

  useEffect(()=>{
    //update active filters.
    const selectedcategoryegories = [];
    if (gamingChecked) selectedcategoryegories.push('gaming');
    if (toolsChecked) selectedcategoryegories.push('tools'); 
    if (componentsChecked) selectedcategoryegories.push('components'); 
    if (roboticsChecked) selectedcategoryegories.push('robotics');
    if (microcontrollersChecked) selectedcategoryegories.push('microcontroller');
    setFiltercategoryegories(selectedcategoryegories);
    
   },
  [componentsChecked,toolsChecked,gamingChecked,roboticsChecked,microcontrollersChecked])

  function removeFilter(filter) {
    //Remove filter when close button is clicked on the current active filter tab.
    if(filter==='gaming') setGamingChecked(false);
    if(filter==='tools') setToolsChecked(false);
    if(filter==='components') setComponentsChecked(false);
    if(filter==='robotics') setRoboticsChecked(false);
    if(filter==='microcontroller') setMicrocontrollersChecked(false); 
  }

  const categoryThumbnails = [
    {
      thumbnail: roboticsThumbnail,
      title: 'Robotics'
    },
    {
      thumbnail: componentsThumbnail,
      title: 'Electronics Components'
    },
    {
      thumbnail: toolsNinstrumentsThumbnail,
      title: 'Tools and Instruments'
    },
    {
      thumbnail: gamingThumbnail,
      title: 'Gaming and Computers'
    },
    {
      thumbnail: ElectricalThumbnail,
      title: 'Electrical'
    },
    {
      thumbnail: MiscellaneousThumbnail,
      title: 'Miscellaneous'
    }
    
  ];

  useEffect(()=>{
    console.log('Category Open',categoryOpen)
    console.log('DB Category Open',dbCategory)
  },[categoryOpen,dbCategory])
  

  return (
    
    <div id='RobNElecComp' className='pl-4 pr-4 w-full items-center min-h-screen relative bg-gray-50'>
      <Helmet>
        <title>Frantech | Products</title>
        <meta charSet='utf-8'/>
        <link rel="canonical" href="/products" />
        <meta name='keywords' content='Electronics, Electrical, Casino, Slot Machine, Graphics Card, Microcontroller, Monitor, Instruments, Capacitor, Electrical Components, Robotics, tools' />
      </Helmet>
      <div className='fixed mr-4 rounded shadow-sm top-20 md:w-1/2 bg-white z-30'>
        <div className="flex md:w-full w-full ">
          <InputGroup className="">
            <Form.Control
              placeholder="Search Product"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
        
            {/**Filter options dropdown */}
            <Dropdown autoClose="outside">
              <Dropdown.Toggle id='dropdown-autoclose-inside'>Filter by Category</Dropdown.Toggle>
              <Dropdown.Menu className='shadow'>
                <Dropdown.Item className='' as='div'>
                  <label htmlFor="gaming">
                    <Form.Check
                      id='gaming'
                      label='Gaming'
                      checked={gamingChecked}
                      onChange={(e)=>setGamingChecked(e.target.checked)}
                    />
                  </label>
                </Dropdown.Item>
                <Dropdown.Item className='' as='div'>
                  <label htmlFor="tools">
                    <Form.Check
                      id='tools'
                      checked={toolsChecked}
                      label='Tools and Instruments'
                      onChange={(e)=>setToolsChecked(e.target.checked)}
                    />
                  </label>
                </Dropdown.Item>
                <Dropdown.Item className='' as='div'>
                  <label htmlFor="components">
                    <Form.Check
                      id='components'
                      checked={componentsChecked}
                      label='Electrical Components'
                      onChange={(e)=>setComponentsChecked(e.target.checked)}
                    />
                  </label>
                </Dropdown.Item>
                <Dropdown.Item className='' as='div'>
                  <label htmlFor="robotics">
                    <Form.Check
                      id='robotics'
                      checked={roboticsChecked}
                      label='Robotics'
                      onChange={(e)=>setRoboticsChecked(e.target.checked)}
                    />
                  </label>
                </Dropdown.Item>
                <Dropdown.Item className='' as='div'>
                  <label htmlFor="microcontrollers">
                    <Form.Check
                      id='microcontrollers'
                      checked={microcontrollersChecked}
                      label='Microcontrollers/Computer Boards'
                      onChange={(e)=>setMicrocontrollersChecked(e.target.checked)}
                    />
                  </label>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </div>
      </div>

      {/*Display Product Highlight */}
      {searchText === '' && filtercategoryegories.length === 0 && categoryOpen === '' &&
       <div>
        {<div>
          {isBusy?(
            <div className='fixed z-30 top-44 left-1/2 w-full'>
              <img src={loadingSVG} alt="Loading" />
            </div>
            ):(
            <div>
              <div className='w-full pt-32 h:1/3 grid sm:grid-cols-2 md:grid-cols-3 place-items-center'>
        {categoryThumbnails.map((category) => (
          <ProductCategories category={category} setCategory={setCategoryOpen} dbCategory={setDBCategory}/>
        ))}
      </div>
            </div>)
          }
        </div>
        
      }
      
        </div>
      }
      {searchText===''&&filtercategoryegories.length===0&&categoryOpen!==''&&
      <div className=' min-h-screen'>
        <div className=' bg-white sticky top-20 z-10' id='instruments'>
            <h2 className='pt-10'>{categoryOpen}</h2>
            <div className=' absolute hidden {md:grid-cols-2 md:grid} md:block items-center justify-center gap-10 h-8 right-2 top-10'>
               {/*<InputGroup as='div' className='w-44' size='sm'>
               <InputGroup.Text>Items per page</InputGroup.Text>
                
                <Form.Select onChange={(e) => setItemsPerPage(e.target.value)} value={itemsPerPage}>
                  <option value={12}>12</option>
                  <option value={24}>24</option>
                  <option value={32}>32</option>
                </Form.Select>
              </InputGroup>*/}
              <Button className='' onClick={()=>{
                setCategoryOpen('');
                setDBCategory('');
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth' // Optional: Use smooth scrolling
                });
              }}><span><i class="fi fi-sr-undo"></i></span>  Back to Categories</Button>
            </div>
            <div className=' absolute block md:hidden right-2 top-11'>
              <CloseButton color='red' onClick={()=>{
                setCategoryOpen('');
                setDBCategory('')
              }}/>
            </div>
            <hr />
          </div>
        <div className="mt-16">
        <div className=''>
          {/**Filter and dispay the products */}
          
            <PaginateItems cart = {cart} updateCart={updateCart} products={filterProducts(dbCategory)} itemsPerPage={20}/>
        
        </div>
        </div>
      </div>
      }

      {/*Display searched products */}
      {searchText !== '' && 
        <div className=' min-h-screen'>
          <div className=' bg-white sticky top-20 z-10' id='instruments'>
            
            <h2 className='pt-10'>{searchText}</h2>
            <Button size='sm' variant='danger' onClick={()=>setSearchText('')}>Clear Search</Button>
           
            <hr />
          </div>
          <div className="mt-16">
            <div className=''>
              {<PaginateItems cart = {cart} updateCart={updateCart} products={searchProducts(searchText)} itemsPerPage={20}/>}
            </div>
          </div>
        
          {searchProducts(searchText).length === 0 && 
            <div className='mt-20 w-full'>
              {enquireProduct &&
                <div className='absolute bg-slate-200 z-40 top-28 border-2 border-slate-300 rounded shadow left-1/3 w-1/3'>
                  <div className="pt-2 px-2">
                    <h4>Enquire Product</h4>
                    <hr />
                    <h6>Please enter your contact details:</h6>
                    <div className=' absolute top-2 right-2'>
                      <CloseButton 
                        onClick={()=>{
                          setMessageSent(false);
                          setSending(false)
                          setEnquireProduct(false)
                        }}
                      />
                    </div>
                  </div>
                  {/**Enquire about product not found modal */}
                  {!sending &&! messageSent &&
                    <div className='pb-2 px-2'>
                      <Form.Control 
                        className='mb-2 mt-2'
                        placeholder='Name'
                        type='name'
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        name='client_Name'
                      />
                      <Form.Control 
                        className='mb-2'
                        placeholder='Email*'
                        type='email'
                        value={client_Email}
                        onChange={(e) => setClientEmail(e.target.value)}
                        name='client_Email'
                      />
                      <Form.Control 
                        className='mb-2'
                        placeholder='Phone'
                        type='tel'
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        value={clientPhone}
                        onChange={(e) => setClientPhone(e.target.value)}
                        name='client_Phone'
                      />
                      <Form.Control
                        type='textarea'
                        as={'textarea'}
                        value={moreInfo}
                        placeholder='Product'
                        onChange={(e) => setMoreInfo(e.target.value)}
                      />
                      <Button className='justify-end mt-2' onClick={sendEnquiry} disabled={client_Email!==''?false:true}>
                        Enquire
                      </Button>
                    </div>
                  }
                  {/**Show loading icon while sending the enquiry. */}
                  {!messageSent && sending &&
                    <div className="w-full-h-full flex justify-center items-center">
                      <img src={loadingSVG} alt="" />
                    </div>
                  }
                  {/**Notify user after successfully sending the enquiry */}
                  {messageSent && !sending &&
                    <div className=" p-4 w-full-h-full flex justify-center items-center">
                      <h5 className=' text-center'>Thank You for getting in touch. One of our representatives will reach out to you as soon as possible.</h5>
                    </div>
                  }
                </div>
              }
              <h4>No result for "{searchText}"</h4>
              <Button onClick={()=>setEnquireProduct(true)}>Enquire about sourcing</Button>
            </div>
          }
        </div>
      }

      {/**Display filtered products */}
      {filtercategoryegories.length !== 0 &&
        <div className=' min-h-screen'>
          <div className=' bg-white sticky top-20 z-20 border-b rounded-b'>
            <div className="flex gap-2">
              <h5 className='mt-10 p-1'>Filters:</h5>
              {/**Display Active filters in this tab */}
              {filtercategoryegories.map((category)=>{
                return (
                  <h5 className='mt-10 p-1 border rounded shadow-sm'>
                    {category}
                    <span className=' text-sm pl-2'>
                      {/**Option to remove filter from the active filter tab */}
                      <CloseButton onClick={()=>removeFilter(category)}/>
                    </span>
                  </h5>
                )
              })}
            </div>
          </div>
          <div className="mt-16">
            <div className=''>
              {/**Filter and dispay the products */}
              
                <PaginateItems cart = {cart} updateCart={updateCart} products={filterProducts(filtercategoryegories)} itemsPerPage={20}/>
              
            </div>
          </div>
            
        </div>
      } 
    </div>
  )
}

export default Products