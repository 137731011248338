import React from 'react'
import Hotslots from '../images/hotslots.png'
import SunIntl from '../images/sunIntl.png'
import Peermont from '../images/peermont-removebg-preview.png'
import TsogoSun from '../images/TsogoSunLogo-removebg-preview.png'
import BuffaloCity from '../images/BCC LOGO.png'
import ElangeniTvet from '../images/elangeni-college-logo.jpg'
import ThekwiniTvet from '../images/thekwini college.jpeg'
import MotheoTvet from '../images/motheo tvet.jpg'
import SedibengTvet from '../images/sedibeng college.png'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { OverlayTrigger } from 'react-bootstrap'
import { popoverBuffalo, popoverElangeni, popoverHotslots, popoverMotheo, popoverPeermont, popoverSedibeng, popoverSunIntl, popoverThekwini, popoverTsogo } from '../constants/Popovers'

function ClientsCarousel() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
    };

    
  return (
    <Carousel 
        responsive={responsive}
        autoPlay 
        autoPlaySpeed={3000} 
        transitionDuration={2000} 
        rewind 
        rewindWithAnimation
        className=''
        >
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.tsogosun.com/" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverTsogo}>
                <img className=' w-4/6 object-cover' src={TsogoSun} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.peermont.com/" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverPeermont}>
                <img className=' w-4/6  object-cover' src={Peermont} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.suninternational.com/gaming/" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverSunIntl}>
                <img className=' w-4/6 object-cover' src={SunIntl} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.hotslots.biz/" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverHotslots}>
                <img className=' w-4/6 ' src={Hotslots} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.elangeni.edu.za" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverElangeni}>
                <img className=' w-4/6 ' src={ElangeniTvet} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="http://www.sedcol.co.za" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverSedibeng}>
                <img className=' w-4/6 ' src={SedibengTvet} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.motheotvet.edu.za" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverMotheo}>
                <img className=' w-4/6 ' src={MotheoTvet} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.bccollege.co.za" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverBuffalo}>
                <img className=' w-4/6 ' src={BuffaloCity} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>
        <div className='w-full h-full flex items-center'>
        <a className='w-full flex justify-center' href="https://www.thekwini.edu.za" target="_blank" rel="noopener noreferrer">
            <OverlayTrigger placement='top' overlay={popoverThekwini}>
                <img className=' w-4/6 ' src={ThekwiniTvet} alt="" srcSet="" />
            </OverlayTrigger>
        </a>
        </div>  
    </Carousel>
  )
}

export default ClientsCarousel